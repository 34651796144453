<template lang="">
  <div v-if="isRouterReady">
    <div id="HeaderZone" ismodulecontainer="true"
      class="HeaderContainer HeaderZoneContainer ZoneContainer HeaderZone ModuleContainer header clearfix ">
      <!-- 移动端的左侧菜单栏 -->
      <div class="move-tab" style="display: none;">
        <ul :class="{nav_menu:true,expand:isActive}">
          <li class="nav_menu-item">
            <a href='/' class="item-title">首页</a>
          </li>
          <li class="nav_menu-item" @click="expandTab"><a href="javascript:void(0);" class="item-title">关于我们</a><span
              class="icon-expand"><svg t="1718260283357" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="2314" width="20" height="20" class="icon">
                <path
                  d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                  fill="#ffffff" p-id="2315"></path>
              </svg></span><!---->
            <ul class="second-tab">
              <li><a href="/about/about" class="">公司简介</a></li>
              <li><a href="/about/development" class="">发展历程</a></li>
              <li><a href="/about/businessDomain" class="">业务版图</a></li>
              <li><a href="/about/corporateCulture" class="">企业文化</a></li>
              <li><a href="/about/staffStyle" class="">员工风采</a></li>
            </ul>
          </li>
          <li class="nav_menu-item" @click="expandTab"><a href="javascript:void(0);" class="item-title">服务与解决方案</a><span
              class="icon-expand"><svg t="1718260283357" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="2314" width="20" height="20" class="icon">
                <path
                  d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                  fill="#ffffff" p-id="2315"></path>
              </svg></span><!---->
            <ul class="second-tab">
              <li v-for="(item,index) in serviceList" :key="index">
                <router-link @click.native="toggleActive"
                  :to="{path:'/service',query:{category_id:item.id,category_title:item.title}}"
                  class="">{{item.title}}</router-link>
              </li>
            </ul>
          </li>
          <li class="nav_menu-item" @click="expandTab"><a href="javascript:void(0);" class="item-title">新闻中心</a><span
              class="icon-expand"><svg t="1718260283357" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="2314" width="20" height="20" class="icon">
                <path
                  d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                  fill="#ffffff" p-id="2315"></path>
              </svg></span><!---->
            <ul class="second-tab">
              <li v-for="(item,index) in newsList" :key="index" class="sub-nav-item"><router-link
                  :to="{path:'/news',query:{category_id:item.id,category_title:item.title}}" class="foot_nav"
                  @click.native="toggleActive">{{item.title}}</router-link>
              </li>
            </ul>
          </li>

          <li class="nav_menu-item">
            <a href='https://wq01.com/huace/' target="_blank" class="item-title">在线画册</a>
          </li>

          <li class="nav_menu-item">
            <router-link @click.native="toggleActive" :to="{path:'/newsdetail',query:{id:1,category_id:1}}"
              class="foot_title">集团全媒体</router-link>
          </li>

          <li class="nav_menu-item" @click="expandTab"><a href="javascript:void(0);" class="item-title">联系我们</a><span
              class="icon-expand"><svg t="1718260283357" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="2314" width="20" height="20" class="icon">
                <path
                  d="M469.333333 469.333333V170.666667h85.333334v298.666666h298.666666v85.333334h-298.666666v298.666666h-85.333334v-298.666666H170.666667v-85.333334h298.666666z"
                  fill="#ffffff" p-id="2315"></path>
              </svg></span><!---->
            <ul class="second-tab">
              <li><router-link @click.native="toggleActive" to="/contact/maps" class="">联系我们</router-link></li>
              <li><router-link @click.native="toggleActive" to="/contact/join" class="">加入我们</router-link></li>
              <li><router-link @click.native="toggleActive" to="/contact/feedback" class="">意见反馈</router-link></li>
            </ul>
          </li>
          <!-- 控制隐藏的按钮 -->
          <div id="toggleMenu" :class="{dele:isDelete}" @click="toggleActive">
            <svg t="1718419459346" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="4291" width="30" height="30">
              <path
                d="M964.794062 100.92562 923.057495 59.190077 511.999488 470.263433 100.938412 59.190077 59.203892 100.92562 470.263945 512 59.232544 923.045727 100.968088 964.78127 511.999488 553.736567 923.057495 964.8089 964.794062 923.073356 553.732985 512Z"
                fill="#CCCCCC" p-id="4292" />
            </svg>
          </div>
        </ul>
      </div>
      <div class="top">
        <div class="topContainer">
          <div class="phone_menu">
            <div class="tabulation" style="display: none;" @click="toggleActive">
              <svg t="1718186797314" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="5022" width="30" height="30">
                <path
                  d="M98.357122 818.73194c-14.213723 0-25.764803 11.532661-25.764803 25.76071 0 14.233166 11.55108 25.770943 25.764803 25.770943M922.866648 818.73194 98.128925 818.73194c-14.21884 0-25.770943 11.532661-25.770943 25.76071 0 14.233166 11.552104 25.770943 25.770943 25.770943l824.737724 0c14.213723 0 25.764803-11.538801 25.764803-25.770943C948.633498 830.262554 937.081395 818.73194 922.866648 818.73194zM98.357122 483.770052c-14.213723 0-25.764803 11.537777-25.764803 25.76992 0 14.229073 11.55108 25.764803 25.764803 25.764803M922.866648 483.770052 98.128925 483.770052c-14.21884 0-25.770943 11.537777-25.770943 25.76992 0 14.229073 11.552104 25.764803 25.770943 25.764803l824.737724 0c14.213723 0 25.764803-11.537777 25.764803-25.764803C948.633498 495.307829 937.081395 483.770052 922.866648 483.770052zM98.357122 148.815327c-14.213723 0-25.764803 11.539824-25.764803 25.768897 0 14.227026 11.55108 25.76378 25.764803 25.76378M98.128925 200.346981l824.737724 0c14.213723 0 25.764803-11.536754 25.764803-25.76378 0-14.229073-11.55108-25.768897-25.764803-25.768897L98.128925 148.814304c-14.21884 0-25.770943 11.539824-25.770943 25.768897C72.359005 188.81125 83.911108 200.346981 98.128925 200.346981z"
                  fill="#2c2c2c" p-id="5023" />
              </svg>
            </div>
            <div style="display: flex; align-items: center; height: 100%;">
              <div class="phone_images">
                <img src="@/assets/img/2ffda3f102a1c57ee0b947db5b2ee434.png" alt="">
              </div>
            </div>
          </div>
          <div class="left">
            <div class="BodyCenter BodyCenter62412470 clearfix">
              <a href="https://wq01.com/" target="">
                <img class="image-animation" src="@/assets/img/2ffda3f102a1c57ee0b947db5b2ee434.png"
                  url="/storage/images/20220814/2ffda3f102a1c57ee0b947db5b2ee434.png" alt="图片展示" delay-inited="true"
                  style="background: none;">
              </a>
            </div>
          </div>
          <div class="right">
            <ul>
              <li id="module_6" class="main-nav-item-group">
                <router-link @click.native="changeIndex(0)" to="/"
                  class="top_style foot_title main-nav-item OneRow navMainItemHover"
                  :class="{'main-nav-item-hover':$route.path==='/'}">首页</router-link>
              </li>
              <li id="module_6" class="main-nav-item-group" @mouseover="handleMouseOver(1)"
                @mouseleave="handleMouseLeave">
                <a href="#" @click.prevent="jumpRoute(1)"
                  :class="{top_style:true,foot_title:true, 'main-nav-item-hover': isHovered===1||isHovered1===1}">关于我们</a>
                <ul style="right: -38px;" v-if="isHovered===1">
                  <li class="sub-nav-item"><router-link @click.native="changeIndex(1)"
                      to="/about/about">公司简介</router-link></li>
                  <li class="sub-nav-item"><router-link @click.native="changeIndex(1)"
                      to="/about/development">发展历程</router-link></li>
                  <li class="sub-nav-item"><router-link @click.native="changeIndex(1)"
                      to="/about/businessDomain">业务版图</router-link></li>
                  <li class="sub-nav-item"><router-link @click.native="changeIndex(1)"
                      to="/about/corporateCulture">企业文化</router-link></li>
                  <li class="sub-nav-item"><router-link @click.native="changeIndex(1)"
                      to="/about/staffStyle">员工风采</router-link></li>
                  <li class="sub-nav-item"><a href="https://wq01.com/huace/index.html" target="_blank">在线画册</a></li>
                </ul>
              </li>

              <li id="module_6" class="main-nav-item-group" @mouseover="handleMouseOver(2)"
                @mouseleave="handleMouseLeave">
                <a href="#" @click.prevent="jumpRoute(2)"
                  :class="{top_style:true,foot_title:true, 'main-nav-item-hover': isHovered===2||isHovered1===2}">服务与解决方案</a>
                <ul style="right: -10px;" v-if="isHovered===2">
                  <li v-for="(item,index) in serviceList" :key="index" class="sub-nav-item"><router-link
                      @click.native="changeIndex(2)"
                      :to="{path:'/service',query:{category_id:item.id,category_title:item.title}}"
                      class="foot_nav">{{item.title}}</router-link>
                  </li>
                </ul>
              </li>

              <li id="module_6" class="main-nav-item-group" @mouseover="handleMouseOver(3)"
                @mouseleave="handleMouseLeave">
                <a href="#" @click.prevent="jumpRoute(3)"
                  :class="{top_style:true,foot_title:true, 'main-nav-item-hover': isHovered===3||isHovered1===3}"> 新闻中心</a>
                <ul v-if="isHovered===3" style="right: -40px;">
                  <li v-for="(item,index) in newsList" :key="index" class="sub-nav-item"><router-link
                      @click.native="changeIndex(3)"
                      :to="{path:'/news',query:{category_id:item.id,category_title:item.title}}"
                      class="foot_nav">{{item.title}}</router-link>
                  </li>
                </ul>
              </li>

              <li id="module_6" class="main-nav-item-group" @mouseover="handleMouseOver(6)"
                @mouseleave="handleMouseLeave">
                <router-link :to="{path:'/news',query:{category_id:2,category_title:'非公党建'}}"
                  @click.native="changeIndex(6)"
                  :class="{top_style:true,foot_title:true,'main-nav-item-hover': isHovered===6||isHovered1===6}">非公党建</router-link>
              </li>

              <li id="module_6" class="main-nav-item-group" @mouseover="handleMouseOver(4)"
                @mouseleave="handleMouseLeave">
                <router-link :to="{path:'/newsdetail',query:{id:1,category_id:1}}" @click.native="changeIndex(4)"
                  :class="{top_style:true,foot_title:true,'main-nav-item-hover': isHovered===4||isHovered1===4}">集团全媒体</router-link>
              </li>

              <li id="module_6" class="main-nav-item-group" @mouseover="handleMouseOver(5)"
                @mouseleave="handleMouseLeave">
                <a href="#" @click.prevent="jumpRoute(5)"
                  :class="{top_style:true,foot_title:true, 'main-nav-item-hover': isHovered===5||isHovered1===5}">联系我们</a>
                <ul style="right: -38px;" v-if="isHovered===5">
                  <li class="sub-nav-item"><router-link @click.native="changeIndex(5)"
                      to="/contact/maps">联系我们</router-link></li>
                  <li class="sub-nav-item"><router-link @click.native="changeIndex(5)"
                      to="/contact/join">加入我们</router-link></li>
                  <li class="sub-nav-item"><router-link @click.native="changeIndex(5)"
                      to="/contact/feedback">意见反馈</router-link></li>
                </ul>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        serviceList: [],
        newsList: [],
        isRouterReady: false,
        isSubMenuVisible: false,
        isHovered: false,
        isHovered1: false,
        isActive: false,
        isSecActive: false,
        isDelete: true,
        isPath: false,
        expandedUls: [],
      }
    },
    mounted() {
      this.getService()
      this.getNews()
      this.isRouterReady = true;
    },
    watch: {
    },
    methods: {
      changeIndex(index) {
        this.isHovered1 = index
      },
      jumpRoute(index){
        this.isHovered1 = index
        switch (index) {
          case 1:
            if (this.$route.path !== '/about/about') {
              this.$router.push('/about/about').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                  throw err;
                }
              });
            }
            break;
          case 2:
            if (this.$route.path !== '/service') {
              this.$router.push({
                path: '/service',
                query: {
                  category_id: this.serviceList[0].id,
                  category_title: this.serviceList[0].title
                }
              }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                  throw err;
                }
              });
            }
            break;
          case 3:
            if (this.$route.path !== '/news') {
              this.$router.push({
                path: '/news',
                query: {
                  category_id: this.newsList[0].id,
                  category_title: this.newsList[0].title
                }
              }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                  throw err;
                }
              });
            }
            break;
          case 5:
            if (this.$route.path !== '/contact/maps') {
              this.$router.push('/contact/maps').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                  throw err;
                }
              });
            }
            break;
        }
      },
      handleMouseOver(index) {
        this.isHovered = index;
        // 在这里调用原始 showSubMenu 函数，并传入适当的参数
      },
      handleMouseLeave() {
        this.isHovered = -1;
        // 在这里调用原始 hideSubMenu 函数（如果有的话）
      },
      async getService() {
        let res = await this.$request.post('service/getAll')
        if (res.data.code === 0) {
          this.serviceList = res.data.data
        }
      },
      async getNews() {
        let res = await this.$request.post('newscategory/getAll')
        if (res.data.code === 0) {
          this.newsList = res.data.data
        }
      },
      // 移动端
      toggleActive() {
        this.isActive = !this.isActive;
        this.isDelete = !this.isDelete;
      },
      expandTab(event) {
        // 阻止事件冒泡到其他元素
        event.stopPropagation();

        // 获取当前点击的元素
        const target = event.target;

        // 递归查找最近的li父元素
        function findClosestLiParent(element) {
          if (element.tagName === 'LI') {
            return element;
          } else if (element.parentElement) {
            return findClosestLiParent(element.parentElement);
          } else {
            return null;
          }
        }

        // 找到最近的li父元素
        const liElement = findClosestLiParent(target);

        // 如果找到的li父元素存在且包含nav_menu-item类名
        if (liElement && liElement.classList.contains("nav_menu-item")) {
          // 获取该li元素下的ul元素
          const ulElement = liElement.querySelector('ul');
          if (ulElement) {
            // 检查当前ul元素是否已经在expandedUls数组中
            const index = this.expandedUls.indexOf(ulElement);
            if (index !== -1) {
              // 如果在数组中，移除它，表示收起列表
              this.expandedUls.splice(index, 1);
              ulElement.classList.remove('expand_sec');
            } else {
              // 如果不在数组中，添加它，表示展开列表
              // 同时，移除其他已展开的列表
              this.expandedUls.forEach(ul => ul.classList.remove('expand_sec'));
              this.expandedUls = [ulElement];
              ulElement.classList.add('expand_sec');
            }
          }
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  .main-nav-item-group>a {
    font-size: 1.1rem;
  }

  .phone_menu {
    display: none;
  }

  .top {
    width: 100%;
    height: 100px;
  }

  .topContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left {
    width: 300px;
  }

  .left .BodyCenter a img {
    width: 70%;
  }

  .right {
    margin-right: 415px;
  }

  .right>ul {
    display: flex;
    flex-wrap: nowrap;
  }

  .right>ul>li {
    padding-left: 5%;
    padding-right: 5%;
  }

  .right ul li ul {
    position: absolute;
    z-index: 999999;
  }

  .right ul li .foot_title {
    display: inline-block;
    height: 100px;
    line-height: 100px;
    white-space: nowrap;
  }

  #module {

    padding: 0px;

  }

  #module {

    position: static;

    z-index: 0;

    top: 65px;

    left: 818px;

    width: 100%;

    height: auto;

  }
  #module_6 .top_style{
    font-weight: 700;
  }

  .expand_sec {
    display: block !important;
  }

  .dele {
    display: none;
  }

  .main-nav-item-hover::before {
    content: "";
    position: absolute;
    width: 70%;
    transition: all 0.2s ease-out 0s;
    -moz-transition: all 0.2s ease-out 0s;
    -webkit-transition: all 0.2s ease-out 0s;
    -o-transition: all 0.2s ease-out 0s;
    border-bottom: 3px solid #1976d2;
    z-index: 1;
    left: 50%;
    transform: translate(-50%);
    bottom: 10px;
  }


  @media screen and (max-width: 768px) {
    .right {
      display: none;
    }

    .left {
      display: none;
    }

    .top {
      height: 100%;
    }

    .top .topContainer {
      height: 100%;
    }

    .tabulation {
      display: block !important;
      position: absolute;
      top: 50%;
      left: 10vw;
      transform: translate(-50%, -50%);
    }

    .phone_menu {
      display: block;
      height: 100%;
    }

    .phone_menu .phone_images {
      width: 50vw;
    }

    .phone_menu .phone_images img {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .header {
      height: 80px;
    }

    .header .move-tab {
      display: block !important;
      position: relative;
      width: 100%;
    }

    .header .move-tab .nav_menu {
      width: 15rem;
      position: -webkit-sticky;
      position: absolute;
      z-index: 999;
      display: block;
      left: 0;
      height: 100vh;
      transition: all 0.5s ease;
      background-color: #333;
      transform: translateX(-100%);
    }

    .header .move-tab .nav_menu .nav_menu-item {
      position: relative;
      width: 100%;
      line-height: 65px;
      border-bottom: 1px solid #666;
    }

    .header .move-tab .nav_menu .nav_menu-item .active {
      display: block;
      max-height: 100%;
    }

    .header .move-tab .nav_menu .nav_menu-item .icon-expand {
      position: absolute;
      right: 20px;
      top: 5px;
    }

    .header .move-tab .nav_menu .nav_menu-item a {
      margin-left: 50px;
      font-size: 15px;
      color: white;
    }

    .header .move-tab .nav_menu .nav_menu-item .second-tab {
      display: none;
    }

    .header .move-tab .nav_menu #toggleMenu {
      position: absolute;
      right: -40px;
      top: 23px;
    }

    .header .move-tab .expand {
      transform: translateX(0);
    }

    .header .container {
      justify-content: space-around;
      width: 100vw;
      background: #fff;
      padding: 0 0.5rem;
    }

    .header .container .tabulation {
      display: block !important;
    }

    .header .container .l {
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    .header .container .l .logo {
      width: 106px;
      margin-right: 0px;
      transition: all 0.5s ease;
    }

    .header .container .l .company-name {
      font-size: 4vw;
      transition: all 0.5s ease;
    }

    .header .container .l .company-name .company-name-en {
      font-size: 2vw;
    }

    .header .container .l .hide-title {
      transform: translateX(350%);
    }

    .header .container .r {
      display: none;
    }

  }
</style>
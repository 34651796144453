import Vue from 'vue'
import Vuex from 'vuex'
import request from '../libs/request.js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    siteList:[]
  },
  getters: {
  },
  mutations: {
    setSite(state,siteList){
      state.siteList=siteList
    }
  },
  actions: {
    async getSite({commit}){
      let res = await request.post('seo/getSite')
      if (res.data.code===0) {
        commit('setSite',res.data.data)
      }else{
        console.error('数据请求失败')
      }
    }
  },
  modules: {
  }
})

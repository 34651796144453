<template lang="">
  <div>
    <!-- 侧边 -->
    <div class="right-nav">
      <div  class="one"  @mouseover="showPhoneContent"
      @mouseleave="hidePhoneContent">
        <div class="content">
          <svg  t="1731934893604" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2867" id="mx_n_1731934893605" width="24" height="24"><path d="M744.448 959.232h-4.693333c-107.434667-5.546667-252.586667-106.666667-388.266667-270.677333l-50.090667-60.586667C165.717333 464.213333 94.634667 304.042667 111.274667 199.68 122.88 126.122667 232.362667 64 294.826667 64c30.72 0 41.984 15.36 45.824 24.490667 35.413333 62.890667 79.189333 172.202667 79.36 211.456v2.730666l-0.938667 2.56c-7.850667 20.650667-25.258667 30.549333-40.704 39.253334-20.309333 11.52-31.829333 19.029333-33.28 40.704-0.341333 6.570667 5.290667 36.522667 94.805333 146.773333l38.314667 46.250667c89.770667 105.898667 117.930667 117.333333 124.586667 118.272 21.674667 2.986667 31.829333-6.485333 47.36-23.552 11.946667-12.970667 25.344-27.733333 47.530666-30.976l2.816-0.426667 2.730667 0.597333c39.082667 8.277333 139.093333 72.789333 195.925333 120.661334 8.192 5.376 22.528 21.589333 11.178667 57.258666-17.749333 56.064-95.232 139.178667-165.888 139.178667z"  p-id="2868"></path></svg>
        </div>
        <div class="underline"> </div>
      </div>
      <div  class="two" @mouseover="showQQContent"
      @mouseleave="hideQQContent">
        <div class="content">
          <svg t="1731938177117" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4201" id="mx_n_1731938177117" width="24" height="24"><path d="M866.991 707.772c0 0-25.341 69.55-71.877 132.071 0 0 83.050 28.439 76.137 102.196 0 0 2.709 82.331-177.613 76.685 0 0-126.817-9.898-164.88-63.85h-33.53c-38.018 54.006-164.834 63.85-164.834 63.85-180.431 5.7-177.556-76.685-177.556-76.685-7.025-73.756 76.081-102.196 76.081-102.196-46.475-62.519-71.875-132.071-71.875-132.071-112.759 183.141-101.477-25.567-101.477-25.567 21.141-123.495 109.949-204.384 109.949-204.384-12.677-112.157 33.805-132.078 33.805-132.078 9.68-346.979 306.473-340.887 312.675-340.722 6.249-0.163 302.931-6.256 312.72 340.723 0 0 46.538 19.866 33.812 132.078 0 0 88.751 80.89 109.886 204.384 0.055-0.001 11.285 208.707-101.421 25.566v0 0z" p-id="4202"></path></svg>
        </div>
      </div>
      <div class="last" @click="scrollTop">
        <div class="content">
          <svg t="1731938385704" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5715" id="mx_n_1731938385705" width="23" height="23"><path d="M960.1 699.7l-72.8 72.8L512 397.1 136.7 772.5l-72.8-72.8L512 251.5z" fill="#ffffff" p-id="5716"></path></svg>
        </div>
      </div>
      <div class="float_content">
         <div  :style="{display:isPhoneContentVisible ?'block':'none'}" class="phone_content">
          <ul>
            <li>
              <div>热线电话</div>
              <div>400-800-4949</div>
            </li>
            <li>
              <div>上班时间</div>
              <div>周一到周五</div>
            </li>
            <li>
              <div>E-mail</div>
              <div>vip@wq01.com</div>
            </li>
          </ul>
         </div>
         <div  :style="{display:isQQContentVisible?'block':'none'}" class="qq_content">
           <div>客服中心</div>
           <div>
            <svg t="1731978808432" class="icon" viewBox="0 0 1066 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5696" width="16" height="16"><path d="M533.333333 85.333333c235.648 0 426.666667 169.813333 426.666667 379.264 0 209.493333-191.018667 379.264-426.666667 379.264-11.946667 0-29.098667 23.466667-71.125333 47.36C421.034667 914.773333 378.88 938.666667 343.722667 938.666667c-71.125333 0 33.706667-119.850667 5.461333-131.84C205.738667 745.6 106.666667 615.338667 106.666667 464.554667 106.666667 255.104 297.685333 85.333333 533.333333 85.333333z m58.282667 444.586667H424.490667c-55.637333 0-55.637333 82.474667 0 82.474667h167.125333c55.68 0 55.68-82.432 0-82.432z m63.104-168.533333H424.533333c-55.68 0-55.68 82.474667 0 82.474666h230.186667c55.68 0 55.68-82.389333 0-82.389333V361.386667z" fill="#1e88e5" p-id="5697"></path></svg>
            <span>服务咨询</span>
           </div>
           <div>
            <img src="@/assets/img/q.png" alt="">
          </div>
         </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isPhoneContentVisible: false,
      isQQContentVisible: false,
    }
  },
  methods: {
    showPhoneContent() {
      this.isPhoneContentVisible = true;
      this.isQQContentVisible = false; // 确保QQ内容隐藏
    },
    hidePhoneContent() {
      this.isPhoneContentVisible = false;
    },
    showQQContent() {
      this.isQQContentVisible = true;
      this.isPhoneContentVisible = false; // 确保电话内容隐藏
    },
    hideQQContent() {
      this.isQQContentVisible = false;
    },
    scrollTop(){
        window.scrollTo({top:0,behavior:'smooth'})
      }
  },
}
</script>
<style lang="scss" scoped>
  .right-nav {
    position: fixed;
    top: 410px;
    right: 20px;
    z-index: 99;
  }

  .right-nav .one {
    position: relative;
    background-color: #FFFFFF;
    width: 50px;
    height: 50px;
    border-top: 1px solid #418dcc;
    border-right: 1px solid #418dcc;
    border-left: 1px solid #418dcc;
    border-radius: 8px 8px 0 0;
    display: flex; 
    justify-content: center; 
    align-items: center; 
  }

  .right-nav .one:hover{
    background-color: #418dcc;
  }
  .right-nav .one .icon{
    fill:#D8D8D8;
  }
  .right-nav .one:hover .icon{
    fill: white ;
  }
  

  .right-nav .two {
    background-color: #FFFFFF;
    border-right: 1px solid #418dcc;
    border-left: 1px solid #418dcc;
    border-bottom: 1px solid #418dcc;
    width: 50px;
    height: 50px;
    border-radius: 0 0 8px 8px;
    display: flex; 
    justify-content: center; 
    align-items: center; 
  }

  .right-nav .two:hover{
    background-color: #418dcc;
  }

  .right-nav .two .icon{
    fill:#D8D8D8
  }

  .right-nav .two:hover .icon{
    fill:white
  }

  .right-nav .last {
    margin-top: 5px;
    background-color: #d1d1d1;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex; 
    justify-content: center; 
    align-items: center; 
  }
  .right-nav .last:hover{
    background-color:#418dcc;
  }
  .right-nav .underline{
    position: absolute;
    width: 25px;
    height: 1px;
    bottom: -1px;
    background-color: #D8D8D8;
  }
  .right-nav .float_content{
    position: relative;
    right: 111px;
    bottom: 154px;
  }
  .right-nav .float_content .phone_content{
    position: absolute;
    display: none;
  }
  .right-nav .float_content .phone_content ul li{
    background-color: #FFFFFF;
    padding:13px;
  }
  .right-nav .float_content .phone_content ul li:first-child{
    border-bottom: 1px solid ;
    border-radius: 8px 8px 0 0;
    border: 1px solid #e6e6e6;
  }
 
  .right-nav .float_content .phone_content ul li:nth-child(2){
    border: 1px solid #e6e6e6;
    border-top: 0;
  }
  .right-nav .float_content .phone_content ul li div:nth-child(2){
    color: #666;
    font-size: 12px;
  }
  .right-nav .float_content .phone_content ul li:last-child{
    border-radius: 0 0 8px 8px;
    border: 1px solid #e6e6e6;
    border-top: 0;
  }
  
  .right-nav .float_content .qq_content{
    display: none;
    width: 210px;
    height: 170px;
    position: absolute;
    top: 45px;
    right: -60px;
    background-color: #FFFFFF;
    padding: 13px;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
  }
  .right-nav .float_content .qq_content svg{
    vertical-align: middle;
  }
  .right-nav .float_content .qq_content span{
    font-size: 12px;
    color: #666666;
  }

  .right-nav .float_content .qq_content div:nth-child(2){
    margin-top: 10px;
  }

  .right-nav .float_content .qq_content div:nth-child(3){
    margin-top: 10px;
    width: 80px;
  }
  @media screen and (max-width:768px){
    .right-nav{
      display: none;
    }
  }
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './libs/router'
import store from './store'
import request from '@/libs/request.js'
import 'animate.css'
import '@/assets/css/bootstrap.min.css'
// import '@/assets/css/iconfont.css'
import '@/assets/css/ModuleMobileNavTpl.css'
import '@/assets/css/ModuleStyleMobile.css'
import '@/assets/css/PageCss.css'
import '@/assets/css/public.css'
import '@/assets/css/slick-theme.css'
import '@/assets/css/slick.css'
import '@/assets/css/swiper.min.css'
import '@/assets/css/about.css'
import '@/assets/css/news.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

// 使用swiper插件
Vue.use(VueAwesomeSwiper)

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

// import VueCaptcha from 'vue-captcha'
// Vue.use(VueCaptcha)

//使用vue-meta
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

 import VueLazyLoad from 'vue-lazyload'
 Vue.use(VueLazyLoad)

import VueGallerySlideshow from 'vue-gallery-slideshow';

Vue.component('VueGallerySlideshow', VueGallerySlideshow);


import DOMPurify  from 'dompurify'
Vue.prototype.$sanitize=function (html) {
  return DOMPurify.sanitize(html);
}

Vue.prototype.$request=request

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

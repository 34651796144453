<template>
  <div id="app" >
    <Top />
    <div id="app-content">
      <router-view  />
    </div>
    <Footer/>
    <RightNav/>
  </div>
</template>
<script>
  import Top from '@/components/top'
  import Footer from '@/components/footer'
  import RightNav from '@/components/rightnav'
  export default {
    components: {
      Top,
      Footer,
      RightNav
    },
    data() {
      return {

      }
    },
    methods: {
     
    },
  }
</script>
<style lang="scss">
 
  #app-content{
    min-height: 100vh !important;
  }
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about/about',
    name: 'about',
    meta: { title: '公司简介' }, 
    component: () => import('../views/about/index.vue')
  },
  {
    path: '/about/development',
    name: 'development',
    meta:{title:'发展历程'},
    component:() => import('../views/about/developmentHistory')
  },
  {
    path: '/about/businessDomain',
    name: 'businessDomain',
    meta:{title:'业务版图'},
    component:() => import('../views/about/businessDomain')
  },
  {
    path: '/about/corporateCulture',
    name: 'corporateCulture',
    meta:{title:'企业文化'},
    component:() => import('../views/about/corporateCulture')
  },
  {
    path: '/about/staffStyle',
    name: 'staffStyle',
    meta:{title:'员工风采'},
    component:() => import('../views/about/staffStyle')
  },
  {
    path: '/service',
    name: 'service',
    // meta:{title:'财税服务'},
    component:() => import('../views/service')
  },
  {
    path: '/news',
    name: 'news',
    // meta:{title:'财税服务'},
    component:() => import('../views/news')
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    // meta:{title:'财税服务'},
    component:() => import('../views/news/detail.vue')
  },
  {
    path: '/contact/maps',
    name: 'maps',
    component:() => import('../views/contact/maps')
  },
  {
    path: '/contact/join',
    name: 'join',
    component:() => import('../views/contact/join')
  },
  {
    path: '/contact/feedback',
    name: 'feedback',
    component:() => import('../views/contact/feedback')
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(){
    return {x:0,y:0};
  }
})


export default router
